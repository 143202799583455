// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-top-bar {
  padding: 1em;
  background: #FFF;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Tool/components/ToolTopBar/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AACJ","sourcesContent":[".tool-top-bar {\n    padding: 1em;\n    background:#FFF;\n    display:flex;\n    position: fixed;\n    top: 0px;\n    left: 0px;\n    right: 0px;\n    z-index:1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
