import React from "react";
import { Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LinkIcon from "@mui/icons-material/Link";
import TuneIcon from "@mui/icons-material/Tune";
import SidebarSetting from "../../SidebarSetting";
import "./style.scss";

const ToolTopBar = ({ onSubmit }) => {
  const [url, setUrl] = React.useState("");
  const [showSettingItem, setShowSettingItem] = React.useState(false);
  const [sidebarSettings, setSidebarSettings] = React.useState({
    format: "square",
    xAxis: 50,
    yAxis: 50,
    scale: 70,
  });
  const [isValid, setIsValid] = React.useState(true);
  const handleButton = (value) => {
    let urlRegex = new RegExp("^(http|https)://[^\\s/$.?#].[^\\s]*$", "i");
    let isValid = urlRegex.test(url);
    setIsValid(isValid);
    if (!isValid) {
      
      return;
    }
    setUrl("");
    onSubmit({
      url: url,
      ...sidebarSettings, 
    });

    window.scrollTo(0, 0);
  };
  const errorMessage = "Please enter a valid URL.";
  const handleSettingSidebarSave = (updatedSettings) => {
    setSidebarSettings(updatedSettings);
    setShowSettingItem(false);  
  };

  return (
    <div className="tool-top-bar">
      {showSettingItem && (
        <SidebarSetting
          item={sidebarSettings}
          onSubmit={handleSettingSidebarSave}
          onClose={() => setShowSettingItem(false)}
        />
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleButton(url);
        }}
        style={{ display: "flex", width: "100%" }}
      >
        <TextField
          placeholder="e.g. https://www.webshop.com/product-1"
          variant="outlined"
          size="small"
          fullWidth
          value={url}
          error={!isValid}
          helperText={!isValid ? errorMessage : ''}
          onChange={(e) => setUrl(e.target.value)}
          sx={{
            mr: 1,
          }}
           
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
          autoFocus
        />
     
        <Button
          variant="contained"
          color="primary"
          sx={{
            boxShadow: "none",
            height:"40px",
          }}
          onClick={() => handleButton(url)}
          
        >
          Generate
        </Button>

        <Button
          onClick={() => setShowSettingItem(true)}  
          sx={{
            boxShadow: "none",
            height:"40px",
          }}
        >
          <TuneIcon />
        </Button>
      </form>
    </div>
  );
};

export default ToolTopBar;