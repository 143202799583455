import React, { useContext } from "react";
import {
  Button,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./style.scss";

const SidebarSetting = ({ item, onClose, onSubmit }) => {

  const [format, setFormat] = React.useState("square");
  const [xAxis, setXAxis] = React.useState(50);
  const [yAxis, setYAxis] = React.useState(50);
  const [scale, setScale] = React.useState(70);
 

  return (
    <div className="sidebar-setting">
      <div className="sidebar-setting__header">
        <div className="sidebar-setting__header__title">  Generation settings</div>
        <IconButton color="primary" size="small" onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      
      </div>

  

    
      <div className="sidebar-setting__box">
        <div className="sidebar-setting__box__title">
          <PhotoSizeSelectLargeIcon
            fontSize="24"
            className="sidebar-setting__box__icon"
          />
          Output settings
        </div>
        <div className="sidebar-setting__box__content">
          <ToggleButtonGroup
            color="primary"
            value={format}
            exclusive
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
            size="small"
            sx={{
              mb: 2,
            }}
          >
            <ToggleButton value="square">Square</ToggleButton>
            <ToggleButton value="portrait">Portrait</ToggleButton>
            <ToggleButton value="landscape">Landscape</ToggleButton>
          </ToggleButtonGroup>

          <Typography fontSize="small" gutterBottom>
            Position on X-axis
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={xAxis}
            valueLabelDisplay="auto"
            onChange={(e, value) => setXAxis(value)}
          />

          <Typography fontSize="small" gutterBottom>
            Position on Y-axis
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={yAxis}
            valueLabelDisplay="auto"
            onChange={(e, value) => setYAxis(value)}
          />

          <Typography fontSize="small" gutterBottom>
            Scale
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={scale}
            valueLabelDisplay="auto"
            onChange={(e, value) => setScale(value)}
          />
        </div>
      </div>

      <div className="sidebar-setting__footer">
        <Button
          variant="contained"
          fullWidth
          sx={{
            boxShadow: "none",
          }}
          // onClick={() => {
          //   console.log('data', {
          //     ...item,
             
          //     format: format,
          //     xAxis: xAxis,
          //     yAxis: yAxis,
          //     scale:scale,
            
          //   })
          // }}
          onClick={() =>
            onSubmit({
              ...item,
             
              format: format,
              xAxis: xAxis,
              yAxis: yAxis,
              scale:scale,
            
            })
    
          }
        >
       Save
        </Button>
      </div>
    </div>
  );
};

export default SidebarSetting;
