import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, LinearProgress, TextField, Typography } from '@mui/material';
import './style.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FullscreenImage from '../FullscreenImage';
import TuneIcon from '@mui/icons-material/Tune';
import IosShareIcon from '@mui/icons-material/IosShare';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ListItem = ({ item, onRemove, onRegenerate, onDeleteImage, onUpdate }) => {
    const [showFullDescription, setShowFullDescription] = React.useState(false);
    const [showFullscreenImage, setShowFullscreenImage] = React.useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    /**
     * Handle download of images as a zip file
     */
    const handleDownload = async () => {
        const zip = new JSZip();
        const urlArray = [];
        for (let i = 1; i < 5; i++) {
            if (item['image_' + i]) {
                urlArray.push(item['image_' + i]);
            }
        }

        const fetchImage = async (url) => {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch ${url}`);
            return response.blob();
        };

        try {
            // Fetch and add images to the zip
            for (const url of urlArray) {
                const imageBlob = await fetchImage(url);

                const fileName = url.split('/').pop(); // Extract file name from URL
                zip.file(fileName, imageBlob);
            }

            // Generate the zip file
            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, 'images.zip');
        } catch (error) {
            console.error(error);
        }
    };

    // Generation time to minute and seconds
    const formatTime = (timeInMinutes) => {
        const totalSeconds = Math.round(timeInMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}m ${seconds}s`;
    };

    const handleShare = () => {
        let shareLink = 'https://acceptance.prodshot.io/tool?import=' + item.uuid;

        // Copy to clipboard
        navigator.clipboard.writeText(shareLink);
        alert('Copied to clipboard');
    };

    return (
        <div className="tool-list-item">
            {showFullscreenImage && showFullscreenImage > 0 && (
                <FullscreenImage nr={showFullscreenImage} item={item} onClose={() => setShowFullscreenImage(false)} />
            )}

            {item.loading && <LinearProgress variant="determinate" value={(item.loadingProgress / 6) * 100} />}
            <div className="tool-list-item__header">
                <div className="tool-list-item__header__icon">
                    {item.loading && <CircularProgress size={24} />}
                    {!item.loading && !item.error && <CheckCircleOutlineIcon fontSize="25" color="success" />}
                    {item.error && <ErrorOutlineIcon fontSize="25" color="error" />}
                </div>
                <div className="tool-list-item__header__context">
                    <div className="tool-list-item__header__title"> {item.title}</div>
                    <a href={item.url} className="tool-list-item__header__url" target="_blank" rel="noreferrer">
                        {item.url}
                    </a>
                </div>
                <div className="tool-list-item__header__actions">
                    <IconButton>
                        <AccessTimeIcon />
                        {item.totalTime !== undefined && (
                            <Typography variant="body2" className="generation-time">
                                {formatTime(item.totalTime)}
                            </Typography>
                        )}
                    </IconButton>
                    <IconButton size="small" onClick={handleDownload}>
                        <FileDownloadOutlinedIcon />
                    </IconButton>

                    <IconButton size="small" onClick={onRemove}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton size="small" onClick={handleShare}>
                        <IosShareIcon />
                    </IconButton>
                </div>
            </div>

            <div className="tool-list-item__content">
                <div className="tool-list-item__content__left">
                    <div className="tool-list-item__content__product-images">
                        {item?.pageData?.productImages?.map((imageItem, i) => (
                            <div key={i} className={'tool-list-item__content__product-images__item ' + (imageItem.src === item.productImage ? 'active' : '')}>
                                <img className="tool-list-item__content__product-images__content" src={imageItem.src} />
                            </div>
                        ))}
                    </div>
                    <div
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className={'tool-list-item__content__description ' + (showFullDescription ? 'full' : '')}>
                        {item.pageData?.description}
                    </div>
                </div>

                <div className="tool-list-item__content__right">
                    {[1, 2, 3, 4].map((index) => (
                        <React.Fragment key={'image_' + index}>
                            {item['image_' + index] ? (
                                <div
                                    className="tool-list-item__content__image"
                                    // onClick={() => setShowFullscreenImage(index)}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}>
                                    <img
                                        className="tool-list-item__content__image__content"
                                        src={item['image_' + index]}
                                        alt={`Image ${index}`} // Added alt text for accessibility
                                    />
                                    {hoveredIndex === index && (
                                        <div className="tool-list-item__hover-content">
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    backgroundColor: '#FFF',
                                                    '&:hover': {
                                                        backgroundColor: '#FFF'
                                                    }
                                                }}
                                                onClick={() => onDeleteImage(item.uuid, `image_${index}`)}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="tool-list-item__content__image">
                                    <div className="tool-list-item__content__image__placeholder"></div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {!item.loading && (
                <div className="tool-list-item__footer">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            boxShadow: 'none'
                        }}
                        endIcon={<TuneIcon />}
                        onClick={onRegenerate}>
                        Regenerate
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ListItem;
