// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1em;
  flex-direction: column;
}
.full-screen-image__header {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0784313725);
}
.full-screen-image__content {
  top: 5em;
  bottom: 2em;
  left: 0px;
  right: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
}
.full-screen-image__content img {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/controllers/Tool/components/FullscreenImage/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,gBAAA;EACA,oDAAA;AACR;AAEI;EACI,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;AAAR;AACQ;EACI,YAAA;AACZ","sourcesContent":[".full-screen-image {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: #FFF;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    padding: 1em;\n    flex-direction: column;\n\n    &__header {\n        padding: 1em;\n        display: flex;\n        justify-content: space-between;\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        background:#FFF;\n        border-bottom: 1px solid #00000014;\n    }\n   \n    &__content {\n        top: 5em;\n        bottom: 2em;\n        left: 0px;\n        right: 0px;\n        position: absolute;\n        display: flex;\n        justify-content: center;\n        img {\n            height: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
