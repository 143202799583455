import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

// Define custom theme
const theme = createTheme({
  /*palette: {
    primary: {
      main: "#9C27B0", // Set your primary color
    },
  },*/
  typography: {
    fontFamily: "Inter, Arial, sans-serif", // Set global font
    h1: {
      fontFamily: "Inter", 
      fontWeight: 500,
      fontSize: "32px",
    },
    body1: {
      fontFamily: "Inter", 
      fontWeight: 400,
      fontSize: "16px",
      color:"var(--text-secondary, #919297);"
    }


    // You can customize other typography variants similarly
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8em",
          textTransform: "none",
        },
      },
    },
    /*
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white", // Set background color
          borderRadius: "1em", // Increase border radius
        },
        notchedOutline: {
          borderRadius: "1em", // Ensure the outline also has the border radius
        },
      },
    },*/
  },
});

export { theme };
