import "./App.css";
import Home from "./controllers/Home/index.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ContextProvider } from "./context.js";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme.js";
import ToolController from "./controllers/Tool/index.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "tool",
    element: <ToolController />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <RouterProvider router={router} />
      </ContextProvider>
    </ThemeProvider>
  );
}

export default App;
