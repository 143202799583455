import React, { useContext } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.scss";

const FullscreenImage = ({ nr, item, onClose }) => {
  return (
    <div className="full-screen-image">
      <div className="full-screen-image__header">
        <div className="full-screen-image__header__title">{item.title}</div>
        <IconButton color="primary" size="small" onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      </div>
      <div className="full-screen-image__content">
        <img src={item["image_" + nr]} alt={`Image ${nr}`} />
      </div>
    </div>
  );
};

export default FullscreenImage;
