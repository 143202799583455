import React, { useContext } from 'react';
import { createImages, generateCopy, parsePage } from '../../helpers/requests';
import ToolTopBar from './components/ToolTopBar';
import ListItem from './components/ListItem';
import SidebarFineTune from './components/SidebarFineTune';
import { Typography } from '@mui/material';
import emptystate from './assets/empty-state.png';
import './style.scss';

const ToolController = () => {
    const getStorageData = () => {
        let list = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : [];

        if (list.length > 0) {
            list.forEach((item) => {
                if (item.loading) {
                    item.loading = false;
                }
            });
        }

        return list;
    };

    const [overview, setOverview] = React.useState(getStorageData());
    const [showFinetuneItem, setShowFinetuneItem] = React.useState(false);

    /**
     * Upon loading compoenent, check if there is an import
     */
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const importData = urlParams.get('import');
        const url = urlParams.get('url');

        if (importData) {
            let importedData = JSON.parse(atob(importData));
            handleImport(importedData);
        }
        if (url) {
            handleSubmit({ url: url });
        }
    }, []);

    /**
     * Import data upon load
     * @param {*} importedData
     */
    const handleImport = (importedData) => {
        setOverview((prev) => {
            let newObject = [importedData, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            return newObject;
        });
    };

    /**
     * Handle submitting of new item
     * @param {*} newObj
     */
    const handleSubmit = (newObj) => {
        setOverview((prev) => {
            const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => (c === 'x' ? (Math.random() * 16) | 0 : ((Math.random() * 16) & 0x3) | 0x8).toString(16));

            newObj.uuid = uuid;
            if (!newObj.title) {
                newObj.title = 'Product ' + (prev.length + 1);
            }
            newObj.loading = true;
            newObj.loadingProgress = 0;
            let newObject = [newObj, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            loadPageData(newObj);
            return newObject;
        });
    };

    /**
     * Handle remove of item
     * @param {*} uuid
     */
    const handleRemove = (uuid) => {
        setOverview((prev) => {
            let newOverview = prev.filter((item) => item.uuid !== uuid);
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    /**
     * Handle tinetune
     * @param {*} item
     */
    const handleFinetune = (item) => {
        setShowFinetuneItem(item);
    };

    /**
     * Regenerate item
     * @param {*} item
     */
    const handleRegenerate = (item) => {
        handleSubmit(item);
        setShowFinetuneItem(false);
        window.scrollTo(0, 0);
    };

    /**
     * Start loading page
     * @param {*} newObj
     */
    const loadPageData = async (inputData) => {
        let newData = inputData;

        // New page
        if (!inputData?.pageData?.title) {
            let pageData = await parsePage(inputData.url);

            if (!pageData || !pageData.description || !pageData.productImages) {
                updateItem(inputData.uuid, {
                    error: true,
                    loading: false,
                    errorMessage: 'Could not fetch data'
                });
            } else {
                let newData = {
                    ...inputData,
                    title: pageData?.title,
                    productImage: pageData.productImages[0]?.src,
                    pageData: pageData,
                    loadingProgress: 1
                };

                updateItem(newData.uuid, newData);
                await generatePrompts(newData);
            }
        }
        // Regenerate, we have page data
        else {
            await generatePrompts(newData);
        }
    };

    /**
     * Generate prompts with AI
     * @param {*} newData
     */
    const generatePrompts = async (newData) => {
        // Existing prompts
        if (newData.imagePrompts && newData.imagePrompts.length > 0) {
            updateItem(newData.uuid, {
                loadingProgress: 2
            });
            await generateImage(newData, 1);
        }
        // Generate new prompts
        else {
            let result = await generateCopy({
                title: newData.pageData.title,
                description: newData.pageData.description,
                imageUrl: newData.productImage
            });

            if (result?.imagePrompts) {
                updateItem(newData.uuid, {
                    imagePrompts: result.imagePrompts,
                    loadingProgress: 2
                });
                await generateImage({ ...newData, imagePrompts: result.imagePrompts }, 1);
            }
        }
    };

    /**
     * Generate image
     * @param {*} newData
     * @param {*} nr
     */
    const generateImage = async (inputData, nr) => {
        let imageOutput = await createImages({
            'x-axis': inputData.xAxis || 50,
            'y-axis': inputData.yAxis || 50,
            scale: inputData.scale || 0.6,
            url: inputData.productImage,
            prompt: inputData.imagePrompts[nr - 1]?.prompt,
            aspect_ratio: 'square'
        });

        // Update item with image
        if (imageOutput?.image_urls[0]) {
            updateItem(inputData.uuid, {
                loadingProgress: nr + 2,
                ['image_' + nr]: imageOutput?.image_urls[0],
                loading: nr < 4
            });
        }
        if (nr < 4) {
            await generateImage(inputData, nr + 1);
        }
    };

    /**
     * Set data to update item
     * @param {*} uuid
     * @param {*} data
     */
    const updateItem = (uuid, data) => {
        setOverview((prev) => {
            let newOverview = prev.map((item) => {
                if (item.uuid === uuid) {
                    return { ...item, ...data };
                }
                console.log('@@item', item);
                return item;
            });
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    const handleDeleteImage = (uuid, field) => {
        updateItem(uuid, {
            [field]: undefined
        });
    };

    return (
        <div className={'tool ' + (showFinetuneItem ? 'sidebar' : '')}>
            {showFinetuneItem && <SidebarFineTune item={showFinetuneItem} onSubmit={handleRegenerate} onClose={() => setShowFinetuneItem(false)} />}
            <ToolTopBar onSubmit={handleSubmit} />

            {(!overview || overview.length === 0) && (
                <div className="tool__placeholder">
                    <img src={emptystate} alt="Empty state" />
                    <div className="tool__placeholder__text">
                        <Typography variant="h1">No generations yet!</Typography>
                        <Typography variant="body1">Generate captivating visuals from your product URL. Please enter it in the top bar above.</Typography>
                    </div>
                </div>
            )}

            <div className={'tool__list'}>
                {overview.map((item, i) => (
                    <ListItem item={item} key={item.uuid} onRemove={() => handleRemove(item.uuid)} onDeleteImage={handleDeleteImage} onRegenerate={() => handleFinetune(item)} />
                ))}
            </div>
        </div>
    );
};

export default ToolController;
