import React from 'react';
import { Link } from 'react-router-dom';
import backgroundLineLeft from './assets/background-lines-left.svg';
import backgroundLineRight from './assets/background-lines-right.svg';
import bottomRadiusImage from './assets/bottom-radius.svg';
import InputField from '..//Inputfield';
import Showcase from './components/Showcase';
import useScrollAnimation from '../../hooks/scrollintoAnimaton';
import useScrollPosition from '../../hooks/scrollPositionAnimation';
import './style.scss';

const Intro = () => {
    const [introTextRef, isIntroTextVisible] = useScrollAnimation();
    const scrollY = useScrollPosition();

    // Apply transform values based on scrollY for a parallax-like effect
    const animateLineRight = {
        transform: `translate(${scrollY * 0.05}px, ${scrollY * -0.05}px)`, // Adjust multiplier for desired effect
        opacity: Math.min(1, 1 - scrollY / 1000) // Fade out as it scrolls up
    };
    const animateLineLeft = {
        transform: `translate(${scrollY * -0.02}px, ${scrollY * 0.05}px)`, // Adjust multiplier for desired effect
        opacity: Math.min(1, 1 - scrollY / 700) // Fade out as it scrolls up
    };

    return (
        <section className="intro">
            <div className="lines">
                <div className="lines-left" style={animateLineLeft}>
                    <img src={backgroundLineLeft} alt="Line left" />
                </div>
                <div className="lines-right" style={animateLineRight}>
                    <img src={backgroundLineRight} alt="Line right" />
                </div>
            </div>
            <div className="intro-container">
                <div ref={introTextRef} className="intro-container-text">
                    <h1>
                        <span className="highlight">Create</span> stunning product
                        <br /> images automatically
                    </h1>
                    <p>
                        Meet Productshot.AI: the AI that turns your product URL into stunning, custom visuals.
                        <br />
                        Tailored content, effortlessly generated!
                    </p>

                    <InputField />
                </div>
            </div>
            <div className="bottom">
                <div className="bottom-radius">
                    <img src={bottomRadiusImage} alt="Rounded component" />
                </div>
                <Showcase />
            </div>
        </section>
    );
};

export default Intro;
